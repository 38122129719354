exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-centri-assistenza-risultati-js": () => import("./../../../src/pages/centri-assistenza/risultati.js" /* webpackChunkName: "component---src-pages-centri-assistenza-risultati-js" */),
  "component---src-pages-configuratore-prodotti-canali-digitali-js": () => import("./../../../src/pages/configuratore-prodotti-canali-digitali.js" /* webpackChunkName: "component---src-pages-configuratore-prodotti-canali-digitali-js" */),
  "component---src-pages-configuratore-prodotti-js": () => import("./../../../src/pages/configuratore-prodotti.js" /* webpackChunkName: "component---src-pages-configuratore-prodotti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-mappa-sito-js": () => import("./../../../src/pages/mappa-sito.js" /* webpackChunkName: "component---src-pages-mappa-sito-js" */),
  "component---src-pages-personalizzazione-offerta-js": () => import("./../../../src/pages/personalizzazione-offerta.js" /* webpackChunkName: "component---src-pages-personalizzazione-offerta-js" */),
  "component---src-templates-landing-offerte-js": () => import("./../../../src/templates/landing-offerte.js" /* webpackChunkName: "component---src-templates-landing-offerte-js" */),
  "component---src-templates-magazine-detail-js": () => import("./../../../src/templates/magazine-detail.js" /* webpackChunkName: "component---src-templates-magazine-detail-js" */),
  "component---src-templates-pagina-dettaglio-js": () => import("./../../../src/templates/pagina-dettaglio.js" /* webpackChunkName: "component---src-templates-pagina-dettaglio-js" */),
  "component---src-templates-pagina-generica-js": () => import("./../../../src/templates/pagina-generica.js" /* webpackChunkName: "component---src-templates-pagina-generica-js" */),
  "component---src-templates-pagina-hub-js": () => import("./../../../src/templates/pagina-hub.js" /* webpackChunkName: "component---src-templates-pagina-hub-js" */),
  "component---src-templates-pagina-privacy-js": () => import("./../../../src/templates/pagina-privacy.js" /* webpackChunkName: "component---src-templates-pagina-privacy-js" */),
  "component---src-templates-pagina-prodotto-js": () => import("./../../../src/templates/pagina-prodotto.js" /* webpackChunkName: "component---src-templates-pagina-prodotto-js" */)
}

